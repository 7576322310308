<template>
  <div class="MarketDivisionManagementPeople">
    <div class="top">
      <div class="t1">
        <span style="margin-right:20px">市场筛选</span>
        <el-select v-model="form.mk_id" placeholder="请选择">
          <el-option
            v-for="item in options1"
            :key="item.id"
            :label="item.comp_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="t2">
        <span style="margin-right:20px">岗位</span>
        <el-select v-model="form.station_status" placeholder="请选择">
          <el-option
            v-for="item in options2"
            :key="item.id"
            :label="item.station_title"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="t3">
        <span style="margin-right:20px">搜索</span>
        <el-input v-model="form.search" placeholder="关键词搜索"></el-input>
      </div>
      <el-button type="primary" @click="screen">筛选</el-button>
      <el-button type="primary" @click="is_show = true">新增</el-button>
    </div>
    <div class="content">
      <el-table
        :data="table_info"
        border-top
        stripe
        style="width: 100%;margin-top:20px"
      >
        <el-table-column prop="comp_name" label="所属市场"> </el-table-column>
        <el-table-column prop="name" label="姓名"> </el-table-column>
        <el-table-column label="免冠头像">
          <template slot-scope="scope">
            <img
              :src="scope.row.icon"
              @click="$refs.img.previewImage(scope.row.icon)"
              style="width:60px;height:80px"
              alt=""
            />
          </template>
        </el-table-column>
        <el-table-column prop="station_title" label="岗位"> </el-table-column>
        <el-table-column prop="tel" label="手机号"> </el-table-column>
        <el-table-column label="证照">
          <template slot-scope="scope">
            <img
              :src="scope.row.license"
              style="width:100px;height:50px"
              alt=""
              @click="$refs.img.previewImage(scope.row.license)"
            />
          </template>
        </el-table-column>

        <el-table-column label="性别">
          <template slot-scope="scope">
            <span>{{ scope.row.sex * 1 === 1 ? '男' : '女' }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="age" label="年龄"> </el-table-column>
        <el-table-column prop="education_title" label="文化程度">
        </el-table-column>
        <el-table-column label="操做">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="alterClick(scope.row)"
              >修 改</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="text-align:right;margin-top:20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        current-page.sync="1"
        :page-sizes="[10, 20, 30]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog :title="title" :visible.sync="is_show" width="40%" center>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="115px"
        class="demo-ruleForm"
        @close="clickclose"
      >
        <div class="between">
          <div>
            <el-form-item label="所属市场" prop="mk_id">
              <el-select v-model="ruleForm.mk_id" placeholder="请选择所属市场">
                <el-option
                  v-for="item in options1"
                  :key="item.id"
                  :label="item.comp_name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属岗位" prop="station_status">
              <el-select
                v-model="ruleForm.station_status"
                placeholder="请选择所属岗位"
              >
                <el-option
                  v-for="item in options2"
                  :key="item.id"
                  :label="item.station_title"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="文化程度" prop="education_status">
              <el-select
                v-model="ruleForm.education_status"
                placeholder="请选择所属岗位"
              >
                <el-option
                  v-for="item in education"
                  :key="item.id"
                  :label="item.education_title"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label=" 证照" prop="license">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :http-request="uploadSectionFile1"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="ruleForm.license"
                  :src="ruleForm.license"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="性别" prop="sex">
              <el-radio v-model="ruleForm.sex" label="1">男</el-radio>
              <el-radio v-model="ruleForm.sex" label="2">女</el-radio>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="管理人员名称" prop="name">
              <el-input
                placeholder="请输入管理人员名称"
                v-model="ruleForm.name"
                style="width:217px"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="tel">
              <el-input
                placeholder="请输入联系电话"
                v-model="ruleForm.tel"
                style="width:217px"
              ></el-input>
            </el-form-item>
            <el-form-item label="管理人员年龄" prop="age">
              <el-input
                placeholder="请输入管理人员年龄"
                v-model="ruleForm.age"
                style="width:217px"
              ></el-input>
            </el-form-item>
            <el-form-item label=" 管理人员头像" prop="icon">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :http-request="uploadSectionFile2"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="ruleForm.icon" :src="ruleForm.icon" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')"
                >立即创建</el-button
              >
            </el-form-item>
          </div>
        </div>
      </el-form>
    </el-dialog>
    <DialogImg v-show="0" ref="img" />
  </div>
</template>

<script>
import {
  getmarket,
  searchstation,
  marketManageList,
  GETmarketManagePage,
  POSTmarketManagePage,
  POSTmarketManageUpdate
} from '../../../api/layout'
import { upload } from '../../../utile/upload'
export default {
  name: 'MarketDivisionManagementPeople',
  data () {
    return {
      options1: '', // 市场筛选
      options2: '', // 岗位
      table_info: [], // 表格数据
      total: 0, // 总页数
      is_show: false,
      title: '添加管理人员',
      education: '', // 文化程度数据
      ID: '',
      form: {
        page: '', // 页数 默认为1
        limit: '', // 条数 默认为10
        mk_id: '', // 市场id
        station_status: '', // 岗位id
        search: '' // 姓名或者电话
      },
      ruleForm: {
        mk_id: '', // 市场对应的id
        name: '', // 管理人员名称
        icon: '', // 管理人员头像
        station_status: '', // 岗位,对应id
        license: '', // 证照
        tel: '', // 联系电话
        sex: '1', // 性别：1为男，2为女
        education_status: '', // 文化程度：对应id
        age: '' // 年龄
      },
      rules: {
        mk_id: [{ required: true, message: '请选择所属市场', trigger: 'blur' }],
        name: [
          { required: true, message: '请输入管理人员名称', trigger: 'blur' }
        ],
        icon: [
          { required: true, message: '请上传管理人员头像', trigger: 'blur' }
        ],
        station_status: [
          { required: true, message: '请选择岗位', trigger: 'blur' }
        ],
        license: [{ required: true, message: '请上传证照', trigger: 'blur' }],
        tel: {
          validator: (rule, value, callback) => {
            if (value === '') {
              callback(new Error('请输入手机号码'))
            } else {
              const ref = /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/
              if (ref.test(this.ruleForm.tel)) {
                callback()
              } else {
                callback(new Error('请输入正确的手机号码'))
              }
            }
          },
          trigger: 'blur',
          required: true
        },
        education_status: [
          { required: true, message: '请选择文化程度', trigger: 'blur' }
        ],
        age: [
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请输入年龄'))
              } else {
                if (typeof (value * 1) === 'number' && value * 1 <= 120) {
                  callback()
                } else {
                  callback(new Error('请输入正确年龄'))
                }
              }
            },
            trigger: 'blur',
            required: true
          }
        ]
      }
    }
  },
  methods: {
    screen () {
      this.getMarketManageList()
    },
    async getMarketManageList () {
      const res = await marketManageList(this.form)
      if (res.code === 200) {
        this.table_info = res.data.data
        this.total = res.data.total
      }
    },
    handleCurrentChange (val) {
      this.form.page = val
      this.getMarketManageList()
    },
    handleSizeChange (val) {
      this.form.limit = val
      this.getMarketManageList()
    },
    // 文化程度
    GET_marketManagePage () {
      GETmarketManagePage().then(res => {
        this.education = res.data.education_data
      })
    },
    // 上传图片的校验
    beforeAvatarUpload (file) {
      const isJPG1 = file.type === 'image/png'
      const isJPG2 = file.type === 'image/jpeg'
      const isJPG3 = file.type === 'image/bmp'
      const isJPG4 = file.type === 'image/gif'
      const img = [isJPG1, isJPG2, isJPG3, isJPG4]
      const some = img.some(item => {
        return item
      })
      if (!some) {
        this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
      }
      return some
    },
    // 获取真实的图片路径
    uploadSectionFile2 (param) {
      // this.ruleForm.icon = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.ruleForm.icon = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    // 获取真实的图片路径
    uploadSectionFile1 (param) {
      // this.ruleForm.license = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.ruleForm.license = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    // 修改
    alterClick (row) {
      this.ID = row.id
      this.title = '修改管理人员'
      this.is_show = true
      this.ruleForm.name = row.name
      this.ruleForm.icon = row.icon
      this.ruleForm.license = row.license
      this.ruleForm.tel = row.tel
      this.ruleForm.sex = `${row.sex}`
      this.ruleForm.age = row.age
      this.options1.forEach(item => {
        if (item.comp_name === row.comp_name) {
          this.ruleForm.mk_id = item.id
        }
      })
      this.options2.forEach(item => {
        if (item.station_title === row.station_title) {
          this.ruleForm.station_status = item.id
        }
      })
      this.education.forEach(item => {
        if (item.education_title === row.education_title) {
          this.ruleForm.education_status = item.id
        }
      })
    },
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const data = {
            ...this.ruleForm
          }
          if (this.title === '添加管理人员') {
            POSTmarketManagePage(data).then(res => {
              if (res.code === 200) {
                this.$message.success(`${res.data}`)
                this.getMarketManageList()
                this.is_show = false
                this.clickclose()
              } else {
                this.$message.error(`${res.msg}`)
              }
            })
          } else {
            data.id = this.ID
            POSTmarketManageUpdate(data).then(res => {
              if (res.code === 200) {
                this.$message.success(`${res.data}`)
                this.getMarketManageList()
                this.is_show = false
                this.clickclose()
              } else {
                this.$message.error(`${res.msg}`)
              }
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 清空
    clickclose () {
      this.title = '添加管理人员'
      this.ID = ''
      this.ruleForm = {
        mk_id: '', // 市场对应的id
        name: '', // 管理人员名称
        icon: '', // 管理人员头像
        station_status: '', // 岗位,对应id
        license: '', // 证照
        tel: '', // 联系电话
        sex: '1', // 性别：1为男，2为女
        education_status: '', // 文化程度：对应id
        age: '' // 年龄
      }
    }
  },
  mounted () {
    getmarket().then(res => {
      this.options1 = res.data

    })
    searchstation().then(res => {
      this.options2 = res.data

    })
    this.getMarketManageList()
    this.GET_marketManagePage()
  }
}
</script>

<style lang="less" scoped>
.MarketDivisionManagementPeople {
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    .t1 {
      margin-right: 20px;
    }
    .t2 {
      margin-right: 20px;
    }
    .t3 {
      margin-right: 20px;
      display: flex;
      justify-content: space-between;
      white-space: nowrap;
      align-items: center;
    }
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
.between {
  display: flex;
  justify-content: space-between;
}
</style>
